import { Injectable, signal, type Type } from '@angular/core';
import type { VmrToastData, HeightT, Toast, ToastTypes } from './types';
import { isArrayWithLength, isNumber } from '@vermeer-corp/it-ng-components/core';

type CreateVmrToastData = VmrToastData & {
  type?: ToastTypes;
  title?: string | Type<unknown>;
  message?: string | Type<unknown>;
};

let _nextUniqueId = 0;

/**
 * Root state service for toasts with methods to create, remove, update.
 * See https://ui.vermeer.com/components/toast.
 */
@Injectable({
  providedIn: 'root'
})
export class VmrToastService {
  private readonly _toasts = signal<Toast[]>([]);
  private readonly _heights = signal<HeightT[]>([]);

  private readonly _sortHeightsFn = (a: HeightT, b: HeightT): number => {
    const toasts = this._toasts();
    const idxA = toasts.findIndex(t => t.id === a.toastId);
    const idxB = toasts.findIndex(t => t.id === b.toastId);
    return idxA - idxB;
  };

  /** Collection of all toasts. */
  readonly toasts = this._toasts.asReadonly();

  /** Collection of all toast heights. */
  readonly heights = this._heights.asReadonly();

  /** Add a new toast to `_toasts`. */
  addToast(data: Toast): void {
    this._toasts.update(prev => [data, ...prev]);
  }

  /** Either add a new toast to `_toasts` or update an existing toast. */
  create(createData: CreateVmrToastData): string | number {
    const {
      message,
      id: dataId,
      type = 'default',
      dismissible = true,
      ...rest
    } = createData;

    const id = isNumber(dataId) || (dataId && dataId?.length > 0) ? dataId : _nextUniqueId++;
    const alreadyExists = this._toasts().some(t => t.id === id);

    if (alreadyExists) {
      this._toasts.update(prev =>
        prev.map(toast => {
          if (toast.id === id) {
            return {
              ...toast,
              ...createData,
              id,
              description: message,
              dismissible,
              type,
              updated: true
            };
          } else {
            return {
              ...toast,
              updated: false
            };
          }
        })
      );
    } else {
      this.addToast({
        ...rest,
        id,
        type,
        dismissible,
        description: message
      });
    }

    return id;
  }

  /** Close all toasts. */
  dismissAll(): void {
    if (isArrayWithLength(this._toasts())) {
      this._toasts.set([]);
    }
  }

  /** Close toast by id. */
  dismiss(id: number | string): number | string {
    const entryExists = this._toasts().some(t => t.id === id);
    if (entryExists) {
      this._toasts.update(prev => prev.filter(toast => toast.id !== id));
    }
    return id;
  }

  /** Are there any active toasts of the specified type - e.g. `success`. */
  anyOfType(type: ToastTypes): boolean {
    return this._toasts().some(t => t.type === type);
  }

  /** Create an `info` toast. */
  info(
    message: string | Type<unknown>,
    data?: VmrToastData
  ): number | string {
    return this.create({ ...data, type: 'info', message });
  }

  /** Create an `error` toast. */
  error(
    message: string | Type<unknown>,
    data?: VmrToastData
  ): number | string {
    return this.create({ ...data, type: 'error', message });
  }

  /** Create a `success` toast. */
  success(
    message: string | Type<unknown>,
    data?: VmrToastData
  ): number | string {
    return this.create({ ...data, type: 'success', message });
  }

  /** Create a `warning` toast. */
  warning(
    message: string | Type<unknown>,
    data?: VmrToastData
  ): number | string {
    return this.create({ ...data, type: 'warning', message });
  }

  /** Create a `loading` toast. */
  loading(
    message: string | Type<unknown>,
    data?: VmrToastData
  ): number | string {
    return this.create({ ...data, type: 'loading', message });
  }

  /** Create an `message` toast (type = `default`). */
  message(
    message: string | Type<unknown>,
    data?: VmrToastData
  ): number | string {
    return this.create({ ...data, message });
  }

  /**
   * Create a custom toast.
   * The `component` parameter must be specified (content renderer).
   */
  custom<T>(component: Type<T>, data?: VmrToastData): number | string {
    const id = data?.id ?? _nextUniqueId++;
    this.create({ component, id, ...data });
    return id;
  }

  /** Wrapper for create method. */
  update(updateData: CreateVmrToastData): string | number {
    return this.create(updateData);
  }

  /** When dismissed, remove a toast's height from collection by toast id. */
  removeHeight(id: number | string): void {
    const entryExists = this._heights().some(h => h.toastId === id);
    if (entryExists) {
      this._heights.update(prev => prev.filter(height => height.toastId !== id));
    }
  }

  /** When created, add a toast's height to collection by toast id. */
  addHeight(height: HeightT): void {
    this._heights.update(prev => [height, ...prev].sort(this._sortHeightsFn));
  }

  /** Reset `_toasts` & `_heights` collections to `[]`. */
  reset(): void {
    this._toasts.set([]);
    this._heights.set([]);
  }
}